<template>
	<div uk-slideshow="ratio: 710:531; animation: fade;">
		<div class="uk-position-relative uk-visible-toggle product-gallery" tabindex="-1">
			<ul class="uk-slideshow-items">
				<li 
					v-for="image in activeProduct.images" 
					class="product-gallery__image"
					:data-src="image.conversions.gallery ? image.conversions.gallery : image.original"
					uk-img="target: !.uk-slideshow-items"
				></li>
			</ul>

			<div 
				v-if="activeProduct.discount_label" 
				class="image-banner image-banner--detail">{{ activeProduct.discount_label }}
			</div>

			<wishlist-button 
				:in-wishlist="activeProduct.in_wishlist" 
				:product-id="activeProduct.id" 
				:routes="routes"
			></wishlist-button>
		</div>

		<div class="product-gallery__nav">
			<button 
				v-for="image in activeProduct.images" 
				class="product-gallery__nav--image"
				:uk-slideshow-item="image.id"
			>
				<img 
					:data-src="image.conversions.thumb_gallery ? image.conversions.thumb_gallery : image.original" 
					:alt="image.description ? image.description : activeProduct.name" 
					uk-img 
				/>
			</button>
		</div>
	</div>
</template>

<script>
import { EventBus } from "../EventBus";

export default {
	name: "ProductImages",
	mounted() {
		EventBus.$on('PRODUCT_CHANGED', product => {
			this.activeProduct = product;
		});
	},
	data() {
		return {
			activeProduct: this.product
		}
	},
	props: {
		product: {
			type: Object,
			required: true
		},
		routes: {
			type: Object,
			required: true
		}
	}
}
</script>

<style scoped></style>