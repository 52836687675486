<template>
    <div class="c-product__add top-left">
        <span role="button" v-if="checked" class="c-product-detail__like" @click.prevent="toggleWishlist">
            <i class="fa-regular fa-trash-can"></i>
        </span>
        <span role="button" v-else class="c-product-detail__like" @click.prevent="toggleWishlist">
            <i class="fa-regular fa-plus"></i>
        </span>
    </div>
</template>

<script>
    import { EventBus } from "../EventBus";

    export default {
        name: "WishlistButton",
        data() {
            return {
                checked: this.inWishlist
            }
        },
        methods: {
            toggleWishlist()
            {
                window.axios.post(this.routes.wishlist.toggleWishlist, {product_id: this.productId})
                    .then(res => {
                        this.checked = !this.checked;

                        if (res?.data?.message) {
                            EventBus.$emit('NOTIFY', res.data.message);
                        }

                        if (res?.data?.products) {
                            EventBus.$emit('WISHLIST_UPDATE', res.data.products);
                        }
                    })
                    .catch(error => {
                        if (error?.response?.message) {
                            EventBus.$emit('NOTIFY', error.response.data.message);
                        }
                    });
            },
        },
        watch: {
            inWishlist(newVal, oldVal) {
                this.checked = newVal;
            }
        },
        props: {
            inWishlist: {
                type: Boolean,
                required: true,
            },
            productId: {
                type: Number,
                required: true,
            },
            routes: {
                type: Object|Array,
                required: true
            },
        }
    }
</script>

<style scoped>

.top-left {
    top: 16px;
    right: 16px;
    cursor: pointer;
}

</style>
