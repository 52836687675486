<template>
	<div>
		<!-- <div class="d-flex flex-sm-row flex-column align-items-baseline">
			<h2 class="my-0 me-3">{{ activeProduct.use_price }}</h2>
			<h5 class="my-0 me-3 old-price" v-if="activeProduct.use_price != activeProduct.price"><s>{{ activeProduct.price }}</s></h5>
			<p class="my-0 body--small" v-if="activeProduct.use_price != product.price">
				<strong>Je bespaart 20%</strong> (bij min 3 stuks)
			</p>
		</div> -->

		<!-- <product-delivery-time 
			v-if="activeProduct.delivery_time" 
			:delivery-time="activeProduct.delivery_time" 
			class="mt-3 mb-4"
		></product-delivery-time> -->

		<!-- <section class="mb-4" v-if="sizes && sizes.length > 1">
			<div class="size-picker">
				<div class="d-flex align-items-baseline mb-2">
					<p class="my-0 me-4"><strong>{{ translations.chose_a }} {{ activeProduct.size_group.name }}</strong></p>
				</div>

				<div class="sizes">
					<product-configurator-size 
						v-for="size in sizes" 
						:size="size"
						:variation="getVariation(colorId, size.id)" 
						:active-size="sizeId" 
						:active-color="colorId"
						:key="size.id" @change="handleChange"
					></product-configurator-size>
				</div>
			</div>
		</section> -->

		<!-- <section class="mb-4" v-if="colors && colors.length > 1">
			<div class="d-flex align-items-baseline mb-2">
				<p class="my-0 me-4"><strong>{{ translations.chose_a }} {{ translations.color }}</strong></p>
			</div>

			<div class="color-picker color-picker--wide">
				<product-configurator-color 
					v-for="color in colors" 
					:color="color"
					:variation="getVariation(color.id, sizeId)" 
					:active-size="sizeId" 
					:active-color="colorId"
					:key="color.id" 
					@change="handleChange"
				></product-configurator-color>
			</div>
		</section> -->

		<!-- <div class="cart-container mt-sm-5">
			<div class="uk-width-1-6@m uk-width-small@s uk-width-1-3 me-3">
				<form class="form-custom">
					<input type="number" name="amount" class="uk-input uk-text-center" v-model="quantity" min="1" />
				</form>
			</div>

			<div>
				<button class="uk-button uk-button-cart uk-width-1-1" @click.prevent="addToCart">
					<img src="/dist/assets/images/icons/cart.svg" width="20" class="me-2 pb-1" v-if="!isLoading" uk-svg />
					<div class="loader" v-else><i class="fa-solid fa-circle-notch fa-spin me-2"></i></div>
					{{ translations.in_my_cart }}
				</button>
			</div>
		</div> -->
	</div>
</template>

<script>
import { EventBus } from "../EventBus";
import ProductConfiguratorColor from "./ProductConfiguratorColor";
import ProductConfiguratorSize from "./ProductConfiguratorSize";
import collect from "collect.js";
import ProductDeliveryTime from "./ProductDeliveryTime";

export default {
	name: "ProductConfigurator",
	components: { ProductDeliveryTime, ProductConfiguratorSize, ProductConfiguratorColor },
	mounted() {
		if (this.allowHistory) {
			window.history.replaceState({ product: this.product }, this.product.name, window.location.origin + this.product.slug);
		}

		window.onpopstate = e => {
			if (e.state?.product) {
				this.setActive(e.state.product);
			}
		}
	},
	data() {
		return {
			activeProduct: this.product,
			quantity: 1,
			isLoading: false,
			colorId: this.product?.color_id,
			sizeId: this.product?.size_id
		}
	},
	computed: {
		discountLabel: function () {
			let label = this.translations.you_save;

		},
		discountMinPiecesLabel: function () {

		}
	},
	methods: {
		handleChange(variation) {
			if (this.redirect) {
				window.location.href = variation.slug;
			} else {
				this.setActive(variation);
				this.addToHistory(variation);
			}
		},
		setActive(variation) {
			this.activeProduct = variation;
			this.colorId = variation?.color_id;
			this.sizeId = variation?.size_id;
			EventBus.$emit('PRODUCT_CHANGED', variation);
		},
		addToHistory(product) {
			if (this.allowHistory) {
				window.history.pushState({ product: product }, product.name, window.location.origin + product.slug);
			}
		},
		getVariation(colorId, sizeId) {
			return collect(this.siblings)
				.where('color_id', colorId)
				.where('size_id', sizeId)
				.first()
		},
		addToCart() {
			this.isLoading = true;

			let quantity = this.quantity;
			if (quantity < 1 || isNaN(quantity)) {
				quantity = 1;
			}

			window.axios.post(this.routes.addToCart, { product_id: this.activeProduct.id, quantity: quantity })
				.then(res => {
					EventBus.$emit('CART_UPDATE', res.data.cart);
					EventBus.$emit('PRODUCT_ADDED_MODAL', { product: this.activeProduct.add_product_modal, quantity: quantity });
					EventBus.$emit("CLOSE_PRODUCT_CARD_CONFIGURATOR");
					this.isLoading = false;
				})
				.catch(error => {
					let message = {
						message: '',
						pos: "top-right",
						status: "danger",
						timeout: 3000,
					};

					EventBus.$emit('NOTIFY', message);
					this.isLoading = false;
				});

		}
	},
	watch: {
		quantity: function (val, oldVal) {
			if (val < 1 && val != "") {
				this.quantity = 1;
			}
		}
	},
	props: {
		product: {
			type: Object | Array,
			required: true
		},
		routes: {
			type: Object | Array,
			required: true
		},
		siblings: {
			type: Object | Array,
			required: false,
			default() { return [] }
		},
		colors: {
			type: Object | Array,
			required: false,
			default() { return [] }
		},
		sizes: {
			type: Object | Array,
			required: false,
			default() { return [] }
		},
		translations: {
			type: Object | Array,
			required: true
		},
		redirect: {
			type: Boolean,
			required: false,
			default: false,
		},
		allowHistory: {
			type: Boolean,
			required: false,
			default: true,
		}
	}
}
</script>

<style scoped></style>