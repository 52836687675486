var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-product__add top-left" }, [
    _vm.checked
      ? _c(
          "span",
          {
            staticClass: "c-product-detail__like",
            attrs: { role: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.toggleWishlist.apply(null, arguments)
              },
            },
          },
          [_c("i", { staticClass: "fa-regular fa-trash-can" })]
        )
      : _c(
          "span",
          {
            staticClass: "c-product-detail__like",
            attrs: { role: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.toggleWishlist.apply(null, arguments)
              },
            },
          },
          [_c("i", { staticClass: "fa-regular fa-plus" })]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }