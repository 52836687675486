var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "a",
      {
        staticClass: "c-product",
        attrs: { href: _vm.product.slug, title: _vm.product.name },
      },
      [
        _c("span", { staticClass: "c-product__header" }, [
          _c("p", { staticClass: "c-product__line" }, [
            _vm._v(_vm._s(_vm.product.filter.name)),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "c-product__title" }, [
            _vm._v(_vm._s(_vm.product.name)),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "c-product__nr" }, [
            _vm._v(_vm._s(_vm.product.sku)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.imageError,
              expression: "imageError",
            },
          ],
          staticClass: "image-fallback",
        }),
        _vm._v(" "),
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.imageError,
              expression: "!imageError",
            },
          ],
          staticClass: "c-product__image",
          attrs: { src: _vm.imageUrl, alt: _vm.product.name },
          on: { error: _vm.onImageError },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }